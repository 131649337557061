import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InfoCard from './InfoCard';
import { converToDollar } from '../../helpers/FormatHelper';
import { Modal, ModalHeader, ModalBody, Badge } from 'reactstrap';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ProductModal = (props) => {
    const history = useHistory();
    const renderModal = () => {
        var selectedProduct = props.selectedProduct;
        return (
            <Dialog open={props.modalOpen} TransitionComponent={Transition} onClose={props.toggleModal} maxWidth={"md"} fullWidth>
                <DialogTitle className="prod-modal-header">
                    <div className="d-flex flex-row align-items-start flex-wrap">
                        <div className="flex-grow-1 prod-modal-header-title">
                            <Typography variant="h5" className="info-light"> {selectedProduct?.product?.name}  </Typography>
                            <div className="d-inline-flex justify-content-start pl-2">
                                <Typography variant="subtitle1" color="secondary" className={"pr-1"}><i className="fa fa-star"> </i></Typography>
                                <Typography variant="subtitle1" color="secondary" className={"pr-1"}><i className="fa fa-star"> </i></Typography>
                                <Typography variant="subtitle1" color="secondary" className={"pr-1"}><i className="fa fa-star"> </i></Typography>
                                <Typography variant="subtitle1" color="secondary" className={"pr-1"}><i className="fa fa-star"> </i></Typography>
                                <Typography variant="subtitle1" color="secondary" className={"pr-1"}><i className="fa fa-star"> </i></Typography>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end prod-modal-header-buttons">
                            {props.isBag ? (<Button className="info-light prod-modal-header-checkout" onClick={props.toggleModal }> Exit  <i className="fa fa-sign-out pl-1"> </i> </Button>):
                                <Button className="info-light prod-modal-header-checkout" onClick={props.toggleModal}> Continue Shopping  <i className="fa fa-sign-out pl-1"> </i> </Button>
                            }
                            <Typography className="prod-modal-header-checkout-mob" onClick={props.toggleModal}> <i className="fa fa-times pl-1"> </i> </Typography>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="d-flex flex-row justify-content-center flex-wrap">
                        <div className="d-flex justify-content-flex-start pr-3 flex-column col-4 product-modal-img-div pb-3">
                            <div className="d-flex justify-content-center">
                                {
                                    selectedProduct?.product?.images?.length > 0 ?
                                        (<img className={"product-modal-img"} src={selectedProduct?.product?.images[0]} alt="Card image" />) :

                                        (<InfoCard title={selectedProduct?.product?.name} />)

                                }
                            </div>
                            <div className="d-flex flex-row justify-content-between mt-4 align-items-center px-1 " >
                                <div className="d-inline-flex flex-grow-1">
                                    {
                                        renderVolumes(selectedProduct?.product)
                                    }

                                </div>
                                <span className="h6 mb-0">{`${converToDollar(selectedProduct?.selectedPrice?.cost)}`} </span>
                            </div>
                            {
                                props.isBag ? (<Button className="bag-button-prods" variant="outlined" size="large" onClick={() => history.push("/products")}>
                                    <span>Return To Products <i className="fa fa-paper-plane pl-2"> </i></span>
                                </Button>) :
                                    (
                                        <Button variant="outlined" size="large" className={props.classes.infoLight} onClick={() => { props.addToBag(selectedProduct); props.showLoadingSpinner() }}>
                                            {props.showSpinner ? <span className="fa fa-spinner fa-spin py-2"> </span> : <span>Add To Bag</span>}
                                        </Button>
                                    )
                            }

                        </div>
                        {renderMobileAccordian(selectedProduct)}
                        <div className={`col-8 details-desk`}>
                            <strong className="font-13 info-light"> Details: </strong>
                            <p className="f-12 mb-2">
                                {selectedProduct?.product?.details}
                            </p>

                            <div>
                                <strong className="font-13 info-light"> Ingredients: </strong>
                                <p className="f-12 mb-2">
                                    {selectedProduct?.product?.ingredients}
                                </p>
                            </div>
                            <div>
                                <strong className="font-13 info-light"> How to Use: </strong>
                                <p className="f-12 mb-2">
                                    {selectedProduct?.product?.howToUse}
                                </p>
                            </div>
                            <div>
                                <strong className="font-13 info-light">  Skin Type: </strong>
                                <p className="f-12 mb-2">
                                    {selectedProduct?.product?.skinType}
                                </p>
                            </div>
                            <div>
                                <strong className="font-13 info-light">  Precaution: </strong>
                                <p className="f-12 mb-2">
                                    {selectedProduct?.product?.precaution}
                                </p>
                            </div>
                            <div>
                                <strong className="font-13 info-light">  Volume: </strong>
                                <p className="f-12 mb-2">
                                    {selectedProduct?.selectedPrice?.size}
                                </p>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    const renderMobileAccordian = (selectedProduct) => {
        return (
            <div className="detail-button-mobile my-2">
                <Accordion>
                    <AccordionSummary
                        expandIcon={<i className="fa fa-caret-down"> </i>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className="info-light" > Show All Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <strong className="font-13 info-light"> Details: </strong>
                            <Typography className="f-12">
                                {selectedProduct?.product?.details}
                            </Typography>

                            <div>
                                <strong className="font-13 info-light"> Ingredients: </strong>
                                <Typography className="f-12">
                                    {selectedProduct?.product?.ingredients}
                                </Typography>
                            </div>
                            <div>
                                <strong className="font-13 info-light"> How to Use: </strong>
                                    <Typography className="f-12">
                                    {selectedProduct?.product?.howToUse}
                                    </Typography>
                            </div>
                            <div>
                                <strong className="font-13 info-light">  Skin Type: </strong>
                                    <Typography className="f-12">
                                    {selectedProduct?.product?.skinType}
                                    </Typography>
                            </div>
                            <div>
                                <strong className="font-13 info-light">  Precaution: </strong>
                                    <Typography className="f-12">
                                    {selectedProduct?.product?.precaution}
                                    </Typography>
                            </div>
                            <div>
                                <strong className="font-13 info-light">  Volume: </strong>
                                    <Typography className="f-12">
                                    {selectedProduct?.selectedPrice?.size}
                                    </Typography>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    }

    const renderVolumes = (product) => {
        var sizesPrices = product?.sizesPrices ? Object.keys(product?.sizesPrices).sort() : null;
        var selectedPrice = props.selectedProduct?.selectedPrice?.size;
        return (
            sizesPrices ? sizesPrices.map((data) =>
                data == selectedPrice ? (
                    <Badge className="mb-0 mr-2 selected-info-badge cursor-pointer d-inline-flex" key={data }>{data} </Badge>
                ) :
                    (<Badge className="mb-0 mr-2 outline-info-badge cursor-pointer d-inline-flex" key={data} onClick={() => props.updateSelectedPrice(data)}>{data} </Badge>)
            ) : null

        )
    }

    return (
        renderModal()
    )


}

export default ProductModal