import React, { Component } from 'react';

export const defaultBagCount = 0;
export const defaultIsLoggedin = false;

export const AppContext = React.createContext({
    bagCount: defaultBagCount, // default value
    isLoggedIn: defaultIsLoggedin,
    bagItems: [],
    purchasedItems: [],
    setPurchasedItems: (items) => { },
    logUserIn: (email) => { },
    addBagItems: (items) => { },
    updateBagItems: (items) => { },
    removeBagItem: (item) => { },
    logUserOut: () => { },
    userEmail: null,
    toggleHeaderImg: (page) => { },
    showHeaderImg: true,
    emptyBag: () => { }
}
);