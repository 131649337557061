import React, { useState, useEffect, useContext } from 'react';
import { Table, Input } from 'reactstrap';
import { AppContext } from './AppContext';
import BagItem from "./Shared/bag-item";
import { useHistory } from 'react-router-dom';
import App from '../App';
import { converToDollar } from '../helpers/FormatHelper';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const Bag = (props) => {

    const appCtx = useContext(AppContext);
    const [curBagItems, setcurBagItems] = useState(appCtx.bagItems);
    //const [purchasedItems, setPurchasedItems] = useState(appCtx.purchasedItems);
    const [showOrderSummary, setShowOrderSummary] = useState(null);
    const history = useHistory();
    useEffect(() => {
        appCtx.toggleHeaderImg('Bag');
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);
        if (query.get("success")) {
            setShowOrderSummary("success");
            appCtx.emptyBag();
        }

        else if (query.get("canceled")) {
            setShowOrderSummary("canceled");
            //setMessage(
            //    "Order canceled -- continue to shop around and checkout when you're ready."
            //);
        }
        else {
            setShowOrderSummary("error");
        }
    }, []);

    const emptyBag = () => {
        return (
            <div className={"mt-5 flex-column justify-content-center align-items-center empty-bag-div"}>
                <div className="d-flex justify-content-center">
                    <div className="card bg-light" style={{ width: 450 }}>
                        <div className="text-center h4 py-3"> <Typography> {"Your bag has no items"} </Typography> </div>
                        <div className="text-center pb-4">
                            <Typography onClick={() => history.push("/products")} variant="h6" className="text-dark text-decoration-underline cursor-pointer"> {"Click here to start shopping"}  </Typography>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    const renderPayCard = () => {
        return (
            <div className="card bg-dark text-white shadow">
                <div className={"border-bottom border-white px-3 py-2"}>
                    <div className={"my-2"} > <Typography> {appCtx.bagCount} {" items"} </Typography> </div>
                </div>
                <div className={"border-bottom border-white px-3 py-2"}>
                    <Typography>{"Promotion Code"} </Typography>
                    <div className={"my-2"} ><Input className={"input"} type={"text"} /> </div>
                </div>
                <div className="d-flex justify-content-center px-3 pt-3">
                    <div className={"flex-grow-1"}> <Typography> {"Subtotal"} </Typography> </div>
                    <div> <Typography> {getBagTotal()} </Typography></div>
                </div>
                <div className="d-flex justify-content-center px-3">
                    <div className={"flex-grow-1"}> <Typography> {"Promotions"} </Typography> </div>
                    <div> <Typography> {0.00} </Typography></div>
                </div>
                <div className="d-flex justify-content-center px-3 mb-3">
                    <div className={"flex-grow-1"}>  <Typography> {"Estimated Tax"}  </Typography> </div>
                    <div> <Typography> {0.00} </Typography></div>
                </div>
                <div className={"border-bottom border-white px-3 mb-3"}>
                </div>
                <div className="d-flex justify-content-center px-3 mb-2">
                    <form className={"w-100"} onSubmit={handlePayment}>
                        <Button  variant="contained" className="w-100 checkout-button" size="large" block type="submit"><span><i className="fa fa-lock pr-2"> </i> Secure Checkout </span></Button>
                    </form>
                </div>
            </div>


        )

    }

    const UpdateBagItem = (item, action) => {
        let curBag = curBagItems;
        if (action == "remove") {
            const newBag = curBag.filter(i => i.id !== item.id);
            setcurBagItems(newBag);
            appCtx.removeBagItem(item);
        }
        else {
            //const newBag = curBag.filter(i => i.id !== item.id);
            //const oldItem = curBag.find(x => x.id == item.id);
            const itemIdx = curBag.findIndex(x => x.id == item.id);
            curBag[itemIdx].quantity = item.quantity;
            setcurBagItems([...curBag]);
            appCtx.updateBagItems([...curBag]);
        }


    }

    const getBagTotal = () => {
        var bagAmt = 0;
        for (var i = 0; i < curBagItems.length; i++) {
            bagAmt += curBagItems[i].quantity * curBagItems[i].selectedPrice.cost;
        }
        var s = converToDollar(bagAmt);
        return s;
    }

    const getPurchasedTotal = () => {
        var bagAmt = 0;
        for (var i = 0; i < appCtx.purchasedItems.length; i++) {
            bagAmt += appCtx.purchasedItems[i].quantity * appCtx.purchasedItems[i].selectedPrice.cost;
        }
        var s = converToDollar(bagAmt);
        return s;
    }


    const handlePayment = async (e) => {
        e.preventDefault();
        const putOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(curBagItems)
        };
        let res = await fetch('Payment', putOptions);
        if (res.ok) {
            let resJson = await res.json();
            if (resJson) {
                window.location.href = resJson;
            }
        }

    };

    const renderOrderSummary = () => {
        var purchasedItems = appCtx.purchasedItems;

        return (
            <>
                <div className="bg-success-light">
                    <Typography variant="h4"  className="bag-ty-font text-center text-success mb-2">{"Thank you for your order! An e-mail will be sent to you shortly. "} </Typography>
                    <Typography variant="h5"  className="text-center text-success mb-3"><i className="fa fa-check-circle pr-2"> </i> {"Order #: 38475940"} </Typography>
                </div>
                <div className="card shadow">
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th colSpan="5" className="text-center">
                                    <Typography>  <b>  {"Summary"} </b> </Typography>
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    <Typography>  <b>    {"Product"} </b> </Typography>
                                </th>
                                <th>
                                    <Typography>  <b>    {"Size"} </b> </Typography>
                                </th>
                                <th>
                                    <Typography>  <b>    {"Price"} </b> </Typography>
                                </th>
                                <th>
                                    <Typography>  <b>    {"Quantity"} </b> </Typography>
                                </th>
                                <th>
                                    <Typography>  <b>  {"Total"} </b> </Typography>
                                </th>
                            </tr>
                        </thead>
                        {
                            purchasedItems.map((data) =>
                                <tr>
                                    {<BagItem imgSrc={data.product.images ? data.product.images[0] : null} name={data.product.name} BagItem={data} description={data.product.description} updateBagItem={(item, action) => UpdateBagItem(item, action)} size={data.selectedPrice?.size} qty={data.quantity} price={data.selectedPrice?.cost} isOrderSummary={true}> </BagItem>}

                                </tr>
                            )
                        }
                        <tfoot>
                            <tr>
                                <td colSpan="5" className="text-center">
                                    <Typography>  <b> {`Total: ${getPurchasedTotal()}`} </b> </Typography>
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                </div>
                <Typography onClick={() => history.push("/products")} variant="h6" className="text-dark text-decoration-underline cursor-pointer text-center mt-5"> {"Not Done Shopping?"}  </Typography>
            </>
        )
    }

    const renderBagStandard = () => {
        return (
            <>
                <Typography variant="h3" className={"center-text"}> {"My Bag"} <i className="fa fa-shopping-bag pl-2"></i> </Typography>
                {
                    curBagItems.length <= 0 ? emptyBag() : (
                        <div className={"mt-5 d-flex div-mobile-pay justify-content-center align-items-center"}>
                            <div className={"col-8 bag-mobile"}>
                                {renderBagTable()}
                                {renderBagItems()}
                            </div>

                            <div className={"col-4 pay-mobile"}>
                                {renderPayCard()}
                            </div>
                        </div >
                    )
                }
            </>
        );
    }

    const renderBagItems = () => {
        return (
            <div className="checkout-mobile">
                {
                    curBagItems.map((data) =>
                        <>
                            {<BagItem imgSrc={data.product.images ? data.product.images[0] : null} name={data.product.name} BagItem={data} description={data.product.description} updateBagItem={(item, action) => UpdateBagItem(item, action)} size={data.selectedPrice?.size} qty={data.quantity} price={data.selectedPrice?.cost}> </BagItem>}

                        </>
                    )
                }
            </div>
        )
    }


    const renderBagTable = () => {

        return (
            <div className="card shadow checkout-desk">
                <Table striped hover >
                    <thead>
                        <tr>
                            <th>
                                <Typography> <b> {"Product"} </b> </Typography>
                            </th>
                            <th>
                                <Typography> <b> {"Size"} </b> </Typography>
                            </th>
                            <th>
                                <Typography>  <b>  {"Price"} </b> </Typography>
                            </th>
                            <th>
                                <Typography> <b>  {"Quantity"} </b> </Typography>
                            </th>
                            <th>
                                <Typography>  <b> {"Total"} </b> </Typography>
                            </th>
                        </tr>
                    </thead>
                    {
                        curBagItems.map((data) =>
                            <tr>
                                {<BagItem imgSrc={data.product.images ? data.product.images[0] : null} name={data.product.name} BagItem={data} description={data.product.description} updateBagItem={(item, action) => UpdateBagItem(item, action)} size={data.selectedPrice?.size} qty={data.quantity} price={data.selectedPrice?.cost}> </BagItem>}

                            </tr>
                        )
                    }
                </Table>
            </div>
        )
    }

    return (
        <div className="container py-5 full-screen">
            {showOrderSummary == "success" ? renderOrderSummary() : renderBagStandard()}
        </div>
    );

}


export default Bag;