import React, { Component } from 'react';
import Testimonial from './Shared/Testimonial';
import { AppContext } from './AppContext';
import Typography from '@material-ui/core/Typography';
import $ from 'jquery';

export class About extends Component {
    static displayName = "About Us";


    componentDidMount() {
        this.context.toggleHeaderImg(null);
       /* this.scrollToDiv();*/
    }
    scrollToDiv = () => {

        $('html, body').animate({
            scrollTop: $("#customContainer").offset().top - 250
        }, 1000);

        //var elem = document.getElementById("customContainer");
        //elem.scrollIntoView({ behavior: "smooth",  });
    }


    render() {
        return (
            <>
                <div className="d-flex flex-row justify-content-center flex-wrap bg-white py-5">
                    <div className="about-text" data-aos="fade-in" data-aos-easing="linear" data-aos-duration="1000">
                        <Typography variant="h3" className="text-center mb-3 welcome-text">About Melissa Miriam Cosmetics</Typography>
                        <div className="d-flex flex-row align-items-stretch flex-wrap justify-content-center">
                            <div className="d-flex flex-column col-6 about-vision"> 
                                <Typography  className="d-block mb-3 about-text-mob">
                                    <b> <u>{"THE VISION"} </u></b>
                                </Typography>
                            <Typography className="d-block about-text-mob" variant="subtitle1">
                                {"Our mission is to offer handcrafted natural products that cater to all skin types by reducing fine lines and wrinkles, relieving skin irritations including rashes, acne, eczema, etc., while healing, hydrating and moisturizing your skin during recovery." }
                                </Typography>
                            </div>
                            <div className="col-6 about-img d-flex justify-content-center" >
                                <img src={"../About_Us.png"} alt="Card image" height="250" width="250"  />
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-center align-items-stretch flex-wrap brown-bg testimonial-container">

                    <div className="w-100 mb-3" data-aos="fade-in" data-aos-easing="linear" data-aos-duration="1000">
                        <Typography variant="h2" className="welcome-text text-center"> Testimonials</Typography>
                    </div>
                    <div className="col-4 testimonial-card" data-aos="fade-in" data-aos-easing="linear" data-aos-duration="1200">
                        <Testimonial
                            title={"Skin Savior"}
                            text={"I recently had surgery on my leg which required the  application of an iodine solution. The solution caused my skin to be very dry and cracked after my surgery. I tried several products but none of them helped, until I tried the MelMir lotion bar. The lotion bar not only helped with the cracked skin but also kept my skin moisturized all day. I highly recommend the original lotion bar for dry skin."}
                            name={"Darren"}
                        />
                    </div>
                    <div className="col-4  testimonial-card" data-aos="fade-in" data-aos-easing="linear" data-aos-duration="1200">
                        <Testimonial
                            title={"Awesome Body Butter"}
                            text={" After my first use, my skin immediately felt hydrated. I will definitely be recommending this product to my friends and family."}
                            name={"Lois"}
                        />
                    </div>
                    <div className="col-4 testimonial-card" data-aos="fade-in" data-aos-easing="linear" data-aos-duration="1200">
                        <Testimonial
                            title={"Top - notch Hydration(Body Butter)"}
                            text={"I have been using the body butters for almost a year and my skin is much softer and nourished.This is a great product that I would recommend to everyone."}
                            name={"Shawn"}
                        />
                    </div>
                </div>
            </>
        );
    }
}

About.contextType = AppContext;

//Name: Lois ;  Title: Awesome Body Butter ;  Text: After my first use, my skin immediately felt hydrated. I will definitely be recommending this product to my friends and family.      
//Name: Shawn; Title: Top - notch Hydration(Body Butter); Text: I have been using the body butters for almost a year and my skin is much softer and nourished.This is a great product that I would recommend to everyone.