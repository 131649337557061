import React, { Component } from 'react';
import InfoCard from './Shared/InfoCard';
import ContactForm from './Shared/ContactForm';
import AOS from 'aos';
import "aos/dist/aos.css";
import LoadingPage from './Shared/LoadingPage';
import { AppContext } from './AppContext';
import { withRouter } from 'react-router-dom'
import { Typography } from '@material-ui/core';
import { HashLink as Link } from 'react-router-hash-link';
import $ from 'jquery';

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };

    }

    componentDidMount() {
        this.context.toggleHeaderImg(null);
        /*this.scrollToDiv();*/
        AOS.init();

        setTimeout(() => {
            this.setState(prevState => ({
                isLoading: false,
            }));
        }, 2000);

    }

    scrollToDiv = () => {

        $('html, body').animate({
            scrollTop: $("#customContainer").offset().top - 250
        }, 1000);

        //var elem = document.getElementById("customContainer");
        //elem.scrollIntoView({ behavior: "smooth",  });
    }

    render() {
        //if (this.state.isLoading) {
        //    return (
        //        <>
        //            <div className={"black-bg text-center welcome-container "}>
        //                <h1 className="welcome-text text-white"> Welcome to Melissa Miriam Cosmetics </h1>
        //            </div>
        //            <LoadingPage> </LoadingPage>
        //        </>
        //    );
        //}

        return (
            <>
                <div className={"brown-bg text-center welcome-container "}>
                    {/*<div data-aos="fade-in" data-aos-easing="linear" data-aos-duration="1000">*/}
                    {/*    <Typography variant="h2" className="welcome-text px-2"> Welcome to Melissa Miriam Cosmetics </Typography>*/}
                    {/*</div>*/}
                </div>


                <div className="brown-bg d-flex flex-column align-items-center">
                    <div className="d-flex flex-row home-row">
                        {/*<div className="w-33 home-card cursor-pointer" >*/}
                        {/*    <Link to="/products#0_div">*/}
                        {/*        <InfoCard imgSrc={"../Soap_Bars.png"} title={"Bath Soap"} />*/}
                        {/*        </Link> */}
                        {/*</div>*/}
                        <div className="home-card cursor-pointer">
                            <Link to="/products#1_div">
                                <InfoCard imgSrc={"../Body_Butter_Home.PNG"} title={"BODY BUTTER"} />
                            </Link>
                        </div>
                        {/*<div className="home-card cursor-pointer">*/}
                        {/*    <Link to="/products#2_div">*/}
                        {/*        <InfoCard imgSrc={"../Body_Lotion.jpg"} title={"BODY LOTION"} />*/}
                        {/*    </Link>*/}
                        {/*</div>*/}

                    </div>
                    {/*<div className="d-flex flex-row home-row">*/}
                    {/*    <div className="home-card cursor-pointer">*/}
                    {/*        <Link to="/products#3_div">*/}
                    {/*            <InfoCard imgSrc={"../Deodorant.jpg"} title={"DEODERANT"} />*/}
                    {/*        </Link>*/}
                    {/*    </div>*/}
                    {/*    <div className="home-card cursor-pointer" >*/}
                    {/*        <Link to="/products#4_div">*/}
                    {/*            <InfoCard imgSrc={"../Face_Cream.jpg"} title={"FACE CREAM"} />*/}
                    {/*        </Link>*/}
                    {/*    </div>*/}
                    {/*    */}{/*<div className="w-33 home-card cursor-pointer">*/}
                    {/*    */}{/*    <Link to="/products#5_div">*/}
                    {/*    */}{/*        <InfoCard imgSrc={"../Lotion_Bar.jpg"} title={"Lotion Bar"} />*/}
                    {/*    */}{/*        </Link>*/}
                    {/*    */}{/*</div>*/}

                    {/*</div>*/}
                </div>
                <div className={"text-center welcome-container-aging brown-bg"}>
                    <div>
                        <Typography variant="h2" className="welcome-text-2 px-2">  Aging is a fact of life. Looking your age is not. -Dr. Howard Murad </Typography>
                    </div>
                </div>


            </>
        );
    }
}


Home.contextType = AppContext;

/*having issues with fade-down for some reason. Gotta fix this part of it up*/

/*commented out last two section 
 //<div className="d-flex flex-row justify-content-center flex-wrap blue-bg">
               //     <div className="w-33 home-card" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="200">
               //         <InfoCard key={"bathBomb"} imgSrc={"../pink_petals_IMG.png"} />
               //     </div>
               //     <div className="w-33 home-card" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="300">
               //         <InfoCard key={"herbImg"} imgSrc={"../Herbs_IMG.png"} />
               //     </div>
               //     <div className="w-33 home-card" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="400">
               //         <InfoCard key={"purpP"} imgSrc={"../white_purple_petals_IMG.png"} />
               //     </div>
               // </div>
               // <div className="d-flex justify-content-center home-contact-container" >
               //     <ContactForm isHomeContact={true} />
               // </div>
               */