import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { AppContext} from './AppContext';

const Login = (props) => {

    const [showSignUp, setShowSignUp] = useState(false);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setconfirmPassword] = useState(null);
    const appCtx = useContext(AppContext);
    const history = useHistory();
   
    const renderLogin = () => {
        return (
            <>
                <Form onSubmit={handleLogin}>
                    <FormGroup>
                        <Input type="text" name="uname_email" id="uname_email" placeHolder="Enter Email" className="p-4 bg-light" onChange={(e) => setEmail(e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Input type="password" name="password" id="password" placeHolder="Enter Password" className="p-4 bg-light"  onChange={(e) => setPassword(e.target.value)} />
                    </FormGroup>
                    <Button className="btn btn-dark btn-lg w-100 my-3 rounded-pill">
                        {"Log In"}
                    </Button>
                </Form>
                <div className="d-flex flex-row py-3">
                    <p className="flex-grow-1 cursor-pointer text-primary text-decoration-underline">Forgot password </p>
                    <p className="cursor-pointer text-primary text-decoration-underline" onClick={() => setShowSignUp(true)}>Sign up </p>
                </div>
            </>
        );
    };
    const renderSignUp = () => {
        return (
            <>
                <Form onSubmit={handleSignUp }>
                    <FormGroup>
                        <Input type="text" name="uname_email" id="uname_email" placeHolder="Enter Email" className="p-4 bg-light" onChange={(e) => setEmail(e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Input type="password" name="password" id="password" placeHolder="Enter Password" className="p-4 bg-light" onChange={(e) => setPassword(e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Input type="password" name="confirm_password" id="confirm_password" className="p-4 bg-light" placeHolder="Confirm Password" onChange={(e) => setconfirmPassword(e.target.value)}  />
                    </FormGroup>
                    <Button className="btn btn-dark btn-lg w-100 my-3 rounded-pill">
                        {"Sign Up"}
                    </Button>
                </Form>
                <div className="d-flex flex-row py-3">
                    <p className="flex-grow-1 cursor-pointer text-primary text-decoration-underline">Forgot password </p>
                    <p className="cursor-pointer text-primary text-decoration-underline" onClick={() => setShowSignUp(false)}>Log In </p>
                </div>
            </>
        );
    };

     const  handleLogin = async (e) => {
        e.preventDefault();

        const putOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: email, password: password })
        };

        let res = await fetch('LogIn/SecureLogIn', putOptions);
         let resJson = await res.json();

         //if comes back with an email. logged in successfully -- display site with logged in
         if (resJson.email != null) {
             updateLogin(resJson.email);
             history.push("/?loggedIn=true");
             props.toggleLoginModal();
         }
         //if comes back with nothing. say user or password incorrect
         else {
             history.push("/?loggedIn=false");
             props.toggleLoginModal();
         }
        
      
    };

    const updateLogin = (email) => {
        appCtx.logUserIn(email);
    }

    const handleSignUp =  async (e) => {
        e.preventDefault();
        const putOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: email, password: password, confirmPassword: confirmPassword })
        };

        let res = await fetch('LogIn/SecureSignUp', putOptions);
        let resJson = await res.json();

        //if comes back with an email. logged in successfully -- display site with logged in
        if (resJson.email != null) {
            updateLogin(resJson.email);
            history.push("/?loggedIn=true");
            props.toggleLoginModal();
        }
        //if comes back with nothing. say user or password incorrect
        else {
            history.push("/?loggedIn=false");
            props.toggleLoginModal();
        }

    };

        return (
            <>
                <div className="d-flex justify-content-center align-items-center">
                    <div className="login-body p-2">
                        {showSignUp == true ? renderSignUp() : renderLogin()}
                    </div>
               </div>
            </>
        );
}

export default Login