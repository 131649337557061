import * as React from 'react';
import { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop';
const LoadingPage = () => {
    const [open, setOpen] = useState(true);
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className="d-flex justify-content-center align-items-center loading-page" >
            {/*<CircularProgress color="inherit"> </CircularProgress>*/}

            <div>
                <Backdrop
                    open={open}
                    onClick={handleClose}
                    style={{zIndex: 100} }
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </div>
    );
}

export default LoadingPage