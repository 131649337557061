import React, { useState } from 'react';
import InfoCard from './InfoCard';
import { converToDollar } from '../../helpers/FormatHelper';
import Typography from '@material-ui/core/Typography';


//props.title
//props.cost
//props
const Product = (props) => {
    const renderMainContent = () => {
        return (
            <InfoCard imgSrc={props.imgSrc} title={props.title} description={props.description} isProduct={true} addToBag={props.addToBag} setSelectedProduct={props.setSelectedProduct} />
        )
    }

    const renderCostData = () => {
        return (
            <>
                <div className={"d-flex flex-row justify-content-center align-items-start mt-2 cursor-pointer"} onClick={() => props.setSelectedProduct()}>
                    <div className={"flex-grow-1 pl-1"}>
                        <Typography variant="h6" className="font-mobile"> {props.title}  </Typography>
                    </div>
                    <div>
                        <Typography variant="subtitle1" className={"px-1 info-light"}><i className="fa fa-info-circle"> </i></Typography>
                    </div>
                </div>
                <div className={"d-flex flex-row justify-content-center align-items-center mt-2 cursor-pointer"} onClick={() => props.setSelectedProduct()}>
                    <div className={"flex-grow-1 pl-1"}>
                        <Typography className="pl-1" variant="h6" color="primary"> {`${converToDollar(props.cost)}`} </Typography>
                    </div>
                    <div className="d-inline-flex">
                        <Typography variant="subtitle1" color="secondary" className={"pr-1"}><i className="fa fa-star"> </i></Typography>
                        <Typography variant="subtitle1" color="secondary" className={"pr-1"}><i className="fa fa-star"> </i></Typography>
                        <Typography variant="subtitle1" color="secondary" className={"pr-1"}><i className="fa fa-star"> </i></Typography>
                        <Typography variant="subtitle1" color="secondary" className={"pr-1"}><i className="fa fa-star"> </i></Typography>
                        <Typography variant="subtitle1" color="secondary" className={"pr-1"}><i className="fa fa-star"> </i></Typography>
                    </div>
                </div>
            </>
        )
    }

    /*    <span className={"pr-1 font-14 text-link"}>{`${converToDollar(props.cost)}`}</span>*/

    return (<>
        {
            renderMainContent()
        }
        {
            renderCostData()
        }
    </>

    );

}

export default Product;
