import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import React, { useState, useContext } from 'react';
import { AppContext } from '../AppContext';
import { useHistory } from 'react-router-dom';
import InfoCard from './InfoCard';
import { converToDollar } from '../../helpers/FormatHelper';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

const DropDown = (props) => {

    const [dropdownOpen, setdropDownOpen] = useState(false);
    const [cartdropdownOpen, setcartdropDownOpen] = useState(false);
    const appCtx = useContext(AppContext);
    const history = useHistory();
    const toggle = () => {
        setdropDownOpen(!dropdownOpen);
    }

    const toggleCart = () => {
        setcartdropDownOpen(!cartdropdownOpen);
    }

    const handleLogOut = () => {
        appCtx.logUserOut();
        history.push("/");
    }


    const removeItem = (item) => {
        appCtx.removeBagItem(item);
    }

    const LoginDrop = () => {
        return (
            <Dropdown className="user-dropdown" isOpen={dropdownOpen} toggle={toggle} direction="left" >
                <DropdownToggle className="btn-light">
                    <i className={"fa fa-user"}> </i>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem className="pl-3"><Typography variant="body2" > {appCtx.userEmail} </Typography> </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem className="pl-3" onClick={handleLogOut}> <Typography variant="body2"> Logout </Typography> </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        )
    }

    const renderCartItems = () => {
        return (
            appCtx.bagItems?.map((data) =>
                <>
                    <DropdownItem key={data.product?.name}>
                        <div className="d-flex flex-row justify-content-center" style={{ minWidth: 275, width: 300 }}>

                            <div className="d-flex justify-content-center pr-3 flex-column col-4">
                                {
                                    data.product?.images?.length > 0 ?
                                        (
                                            <Avatar src={data.product?.images[0]} alt="Card image" style={{ width: 85, height: 85 }}>
                                            </Avatar>
                                            )
                                             :

                                        (
                                            <Avatar className="bg-gradient" style={{ width: 85, height: 85 }}>
                                                {data.product?.name.charAt(0) }
                                        </Avatar>
                                        )

                                }

                            </div>
                            <div className="d-flex justify-content-center pr-3 flex-column col-8" >
                                <Typography variant="body2" className="mb-0 text-wrap">{data.product?.name} </Typography>
                                <Typography variant="body2" className="mb-0">{data.selectedPrice?.size} </Typography>
                                <Typography variant="body2" className="mb-0">{`${converToDollar(data.selectedPrice?.cost)}`} </Typography>
                                <Typography variant="body2" className="mb-0">{data.quantity} <i className="fa fa-asterisk text-danger"> </i> </Typography>
                                <Typography variant="body2" className="mb-0 info-light">{"Edit"} </Typography>
                            </div>

                        </div>

                    </DropdownItem >
                    <DropdownItem divider />
                </>
            )

        )
    }

    const getBagTotal = () => {
        var bagAmt = 0;
        var curBagItems = appCtx.bagItems;
        for (var i = 0; i < curBagItems.length; i++) {
            bagAmt += curBagItems[i].quantity * curBagItems[i].selectedPrice.cost;
        }
        var s = converToDollar(bagAmt);
        return s;
    }

    const renderTotalCheckOut = () => {

        return (

            appCtx.bagCount > 0 ?
                (
                    <div className="d-flex justify-content-center flex-row px-3 mb-3 flex-wrap">
                        <div className={"flex-grow-1 py-2 pl-2"}> <b> {"SUBTOTAL"} </b> </div>
                        <div className="py-2 pr-2"> <b> {getBagTotal()} </b> </div>
                        <button className="btn btn-dark btn-block p-2" onClick={() => history.push("/shopping-bag")}> View Bag & Check Out</button>
                    </div>
                ) : null
            //<button className="btn btn-dark "> View Bag & Check Out</button>
        );
    }

    const cartDrop = () => {
        return (
            <Dropdown className={props.isMobile ? "cart-dropDown-mobile" : "cart-dropDown"} isOpen={cartdropdownOpen} toggle={props.isMobile ? toggleCart : null} onClick={props.isMobile ? toggleCart : () => history.push("/shopping-bag")} direction="left">
                <DropdownToggle className="btn-black">
                    <span> <i className="fa fa-shopping-bag"></i></span>
                    <span> {appCtx.bagCount}</span>
                </DropdownToggle>
                <DropdownMenu >
                    <>
                        <div className="cart-menu">
                            {renderCartItems()}
                        </div>
                        {renderTotalCheckOut()}
                    </>
                </DropdownMenu>
            </Dropdown>
        )
    }

    return (
        props.isUser ? LoginDrop() : cartDrop()
    );
}

export default DropDown;