import React, { useContext, useState } from 'react';
import { AppContext } from '../AppContext';
import { converToDollar } from '../../helpers/FormatHelper';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, Badge } from 'reactstrap';
import ProductModal from './ProductModal';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
const BagItem = (props) => {
    const [dropdownOpen, setdropDownOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const appCtx = useContext(AppContext);
    const toggle = () => {
        setdropDownOpen(!dropdownOpen);
    }

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    }

    const decreaseQty = () => {
        let qty = props.BagItem.quantity;
        if (qty - 1 > 0) {
            let newItem = props.BagItem;
            newItem.quantity = qty - 1;
            props.updateBagItem(newItem, 'decrease');
        }

    }

    const increaseQty = () => {
        let qty = props.BagItem.quantity;
        let newItem = props.BagItem;
        newItem.quantity = qty + 1;
        props.updateBagItem(newItem, 'add');
    }

    const removeItem = () => {
        props.updateBagItem(props.BagItem, 'remove');
    }

    const changeSize = (size) => {
        let newItem = props.BagItem;
        var price = newItem.product.sizesPrices[size];
        newItem.selectedPrice.size = size;
        newItem.selectedPrice.cost = price;
        props.updateBagItem(newItem, 'update')
    }

    const renderNoImg = () => {
        return (
            <div className={`card bg-secondary bg-gradient text-dark`} style={{ maxWidth: 100 }}>
                <div className="card-body d-flex flex-column justify-content-center align-items-center">
                    <h2> <span><i className="fa fa-image"> </i> </span></h2>
                </div>
            </div>
        );
    }

    const renderTableBagItem = () => {
        return (
            <>
                <td className="checkout-desk">
                    <div className={"d-flex justify-content-flex-start align-items-stretch row m-r2"}>
                        <div className={"bag-item-img col-4 pr-0 cursor-pointer"} onClick={toggleModal}>
                            {
                                props.imgSrc?
                                    (
                                        <Avatar  src={props.imgSrc} variant="square" alt="Card image" style={{width: 100, height:100} }>
                                        </Avatar>
                                    )
                                    :

                                    (
                                        <Avatar className="bg-gradient" variant="square" style={{ width: 100, height: 100}}>
                                            {props.name.charAt(0)}
                                        </Avatar>
                                    )

                            }
                        </div>
                        <div className={"col-8 pl-0"}>
                            <div className="d-flex justify-content-center flex-column h-100">
                                <div className={"pl-2 cursor-pointer"} onClick={toggleModal}>
                                    <Typography className="text-primary"> {props.name} </Typography>
                                </div>
                                <div className={"pl-2"}><span className={"text-danger cursor-pointer"} onClick={() => removeItem()}><i className={"fa fa-trash"}> {"remove"} </i> </span></div>
                            </div>
                        </div>

                    </div>
                </td>
                <td className="checkout-desk">
                    <div className="d-flex justify-content-center flex-column" style={{ minHeight: 100 }}>
                        {renderSizeDrop()}
                    </div>
                </td>
                <td className="checkout-desk">
                    <div className="d-flex justify-content-center flex-column" style={{ minHeight: 100 }}>
                        {converToDollar(props.price)}
                    </div>
                </td>
                <td className="checkout-desk">
                    <div className="d-flex justify-content-center flex-column" style={{ minHeight: 100 }}>
                        {renderQty()}
                    </div>

                </td>
                <td className="checkout-desk">
                    <div className="d-flex justify-content-center flex-column" style={{ minHeight: 100 }}>
                        {converToDollar(props.qty * props.price)}
                    </div>
                </td>
            </>
        )
    }


    const renderQty = () => {
        return (
            <div className={"d-inline-flex"}>
                <Typography className={"mr-2"} onClick={() => decreaseQty()}><i className={"fa fa-minus"}> </i>  </Typography>
                <Typography className={"mr-2"}> {props.BagItem.quantity} </Typography>
                <Typography className={"mr-2"} onClick={() => increaseQty()}><i className={"fa fa-plus"}> </i> </Typography>
            </div>
        )

    }

    const renderSizeDrop = () => {
        var sizesPrices = props.BagItem?.product?.sizesPrices ? Object.keys(props.BagItem?.product?.sizesPrices).sort() : null;
        var selectedPrice = props.BagItem?.selectedPrice?.size;
        return (
            <Dropdown isOpen={dropdownOpen} toggle={toggle} >
                <DropdownToggle className="btn btn-dark" caret>
                   {selectedPrice} 
                </DropdownToggle>
                <DropdownMenu>
                    {
                        sizesPrices ? sizesPrices.map((data) =>
                            <DropdownItem onClick={() => changeSize(data)}>
                               {data} 
                            </DropdownItem>
                        ) : null}
                </DropdownMenu>
            </Dropdown>
        )
    }

    const renderVolumes = () => {
        var sizesPrices = props.BagItem?.product?.sizesPrices ? Object.keys(props.BagItem?.product?.sizesPrices).sort() : null;
        var selectedPrice = props.BagItem?.selectedPrice?.size;
        return (
            sizesPrices ?
                <div className="d-inline-flex my-1">
                    {
                        sizesPrices.map((data) =>
                            data == selectedPrice ? (
                                <Badge className="mb-0 mr-2 selected-info-badge cursor-pointer">{data} </Badge>
                            ) :
                                (<Badge className="mb-0 mr-2 outline-info-badge cursor-pointer" onClick={() => changeSize(data)}>{data} </Badge>)
                        )
                    }
                </div>
                : null

        )
    }

    const renderMobileItem = () => {
        return (
            props.isOrderSummary? null : 
            <div className={"my-2 mx-0 checkout-mobile"}>
                <div className={"d-flex justify-content-flex-start my-2 flex-noWrap"}>
                    <div className={"bag-item-img px-0"} onClick={toggleModal}>
                            {
                                props.imgSrc ?
                                    (
                                        <Avatar src={props.imgSrc} variant="square" alt="Card image" style={{ width: 100, height: 150 }}>
                                        </Avatar>
                                    )
                                    :

                                    (
                                        <Avatar className="bg-gradient" variant="square" style={{ width: 100, height: 150 }}>
                                            {props.name.charAt(0)}
                                        </Avatar>
                                    )

                            }
                    </div>
                    <div className={"pl-3 d-flex flex-column justify-content-between"}>
                        <div>
                                <Typography className="f-14 mb-2 text-primary" onClick={toggleModal}> {props.name} </Typography>
                                <Typography className="h6 mb-2"> {`${converToDollar(props.price)}`} </Typography>
                            <div className="d-flex justify-content-center flex-column mb-2">
                                {renderVolumes()}
                            </div>
                            {renderQty()}
                                <div><Typography className="text-danger h6" onClick={() => removeItem()}><i className={"fa fa-trash"}> {"remove"} </i> </Typography></div>
                        </div>
                    </div>

                </div>
            </div >
        )
    }

    const renderTableSummary = () => {
        var selectedPrice = props.BagItem?.selectedPrice?.size;

        return (
            <>
                <td>
                    <div className={"d-flex justify-content-flex-start align-items-stretch row m-r2 flex-nowrap"}>
                        <div className={"bag-item-img pl-3 cursor-pointer hide-mobile"} onClick={toggleModal}>
                            {props.imgSrc ? (<img src={props.imgSrc} />) : renderNoImg()}
                        </div>
                        <div className="d-flex justify-content-start align-items-center flex-column h-100">
                            <div className={"pl-2 cursor-pointer h-100"} onClick={toggleModal}>
                                <Typography className="text-primary bag-prod-name"> {props.name} </Typography>
                            </div>
                        </div>
                    </div>
                </td>

                <td>
                    <div className="d-flex justify-content-center flex-column" >
                        <Typography> {selectedPrice} </Typography>
                    </div>
                </td>
                <td>
                    <div className="d-flex justify-content-center flex-column" >
                        <Typography> {converToDollar(props.price)} </Typography>
                    </div>
                </td>
                <td>
                    <div className="d-flex justify-content-center flex-column" >
                        <Typography> {props.qty} </Typography>
                    </div>

                </td>
                <td>
                    <div className="d-flex justify-content-center flex-column" >
                        <Typography>  {converToDollar(props.qty * props.price)}  </Typography>
                    </div>
                </td>
            </>
        )
    }

    const renderModal = () => {
        var selectedProduct = props.BagItem;
        return (
            props.isOrderSummary? null:  (
            <ProductModal
                selectedProduct={selectedProduct}
                modalOpen={modalOpen}
                toggleModal={toggleModal}
                isBag={true}
                updateSelectedPrice={changeSize}
            >

                </ProductModal>
                )
        );

    }

    return (
        <>
            {props.isOrderSummary ? renderTableSummary() : renderTableBagItem()}
            {renderMobileItem()}
            {
                renderModal()
            }
        </>

    )
}

export default BagItem


//how to enable stripe for checkout. 
/*https://stripe.com/docs/checkout/quickstart?client=react*/

