import React, { useEffect, useState } from 'react';
import { Card, CardText, CardBody, CardTitle, CardFooter } from 'reactstrap';
import AOS from 'aos';
import "aos/dist/aos.css";


const Testimonial = (props) => {

    useEffect(() => {
        AOS.init();
    }, [])
    

    return (<>
        <Card className="h-100" style={{ backgroundColor: 'transparent', border: 'solid 1px #00000073' }}>
            <CardBody>
                <CardTitle ><h6 className="testimonial-text">{props.title} </h6></CardTitle>
                <CardText>{ props.text}</CardText>
            </CardBody>
            <CardFooter style={{ borderTop:'none' }}> <h6 className="testimonial-text">{`-${props.name}`} </h6></CardFooter>
            </Card>
       </>
    );

}

export default Testimonial;
