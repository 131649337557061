import React, { Component } from 'react';
import { Container, NavLink } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { Link } from 'react-router-dom';

export class Layout extends Component {

    renderFooter() {
        return (
            <footer className="footer text-muted cf">
                <div className="container-fluid footer-container d-flex flex-direction-row justify-content-center mt-2 ">

                    <div className="text-center footer-center">
                        <div> <h5>Socials</h5> </div>
                        <div className="d-flex flex-row">
                            <div className="simp-flex-footer">
                                <a href="https://www.instagram.com/melmircosmetics"className="text-muted mx-2 footer-link"><i className="fa fa-instagram"> </i></a>
                            </div>
                            <div className="simp-flex-footer">
                                <a href="mailto:customerservice@melmircosmetics.com" className="text-muted mx-2 footer-link"><i className="fa fa-envelope"> </i></a>
                            </div>
                        </div>
                    </div>
                    <div className="text-center footer-center footer-mobile">
                        <div className="w-100"> <h5>Quick Links</h5> </div>
                        <div className="d-flex flex-row footer-quick-links">
                            <div className="simp-flex-footer">
                                <NavLink tag={Link} className="pr-2 text-muted footer-link" to="/">Home</NavLink>
                            </div>
                            <div className="simp-flex-footer">
                                <NavLink tag={Link} className="pr-2 text-muted footer-link" to="/About">About</NavLink>
                            </div>
                            <div className="simp-flex-footer">
                                <NavLink tag={Link} className="pr-2 text-muted footer-link" to="/products">Our Products</NavLink>
                            </div>
                            <div className="simp-flex-footer">
                                <NavLink tag={Link} className="pr-2 text-muted footer-link" to="/contact">Contact Us</NavLink>
                            </div>
                            <div className="simp-flex-footer">
                                <NavLink tag={Link} className="pr-2 text-muted footer-link" to="/shopping-bag">Cart</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="footer-center text-center">
                        <div className="w-100 copyright-text"> <h5>Copyright</h5> </div>
                        <span className="text-muted">  &copy; 2023 Melissa Miriam Cosmetics LLC </span>
                    </div>

                </div>
                {/*<div className="footer-splitter w-100 my-3">*/}
                {/*</div>*/}

            </footer>
        );
    }

    render() {
        return (
                <div>
                    <NavMenu />
                    <Container fluid className={"custom-container bg-white"} id="customContainer">
                        {this.props.children}
                    </Container>
                    {this.renderFooter()}
                </div>
        );
    }
}
