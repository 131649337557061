import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';

const InfoCard = (props) => {


    const [showTitle, setShowTitle] = useState(false);
    const [showAddProduct, setShowAddProduct] = useState(false);
    const [itemAdded, setItemAdded] = useState(false);
    const [imgLoading, setImgLoading] = useState(true);
    //  const [selectedProduct, setSelectedProduct] = useState({});

    const renderNoImg = () => {
        return (
            <div className={`card bg-secondary bg-gradient text-dark flex-grow-1 w-100 border-rounded ${props.isDropDown == true ? 'cart-dropdown-prodNoImg' : 'product-noIMG'}`}>
                <div className="card-body d-flex flex-column justify-content-center align-items-center">
                    {props.isDropDown == true ? null : (<h3 className="card-title pb-3 text-center">{"Melmir Cosmetics"}</h3>)}
                    <h2> <span><i className="fa fa-image"> </i> </span></h2>
                </div>
            </div>
        );
    }

    const renderWithImg = () => {
        //render both divs, hide one and display the other when done. 
        return (
            <>
                <a onMouseEnter={() => setShowTitle(true)}
                    onMouseLeave={() => setShowTitle(false)}
                    className={imgLoading ? "d-none" : "d-block"}
                >
                    <div className="card bg-transparent text-white home-img-cont">
                        <img className={showTitle == true && props.title ? "home-img-cont-img card-img" : "card-img"} src={props.imgSrc} alt="Card image" onLoad={imageLoaded} />
                        <div className="card-img-overlay d-flex justify-content-center align-items-center">
                            {showTitle == true && props.title ?
                                (<h1 className="card-title">{props.title}</h1>) : null
                            }
                        </div>
                    </div>
                </a>
                <div className={imgLoading ? "card bg-secondary bg-gradient text-dark img-loading flex-grow-1" : "d-none"}>
                    <div className="card-body d-flex flex-column justify-content-center align-items-center">
                        <h1 className="card-title text-center pb-3">{"Melmir Cosmetics"}</h1>
                        <h2> <span><i className="fa fa-image"> </i> </span></h2>
                    </div>
                </div>

            </>
        );
    }

    const addBagItem = (e) => {
        //setItemAdded(true);
        //props.addToBag();
        props.setSelectedProduct();
    }

    const setSelectedItem = () => {
        props.setSelectedProduct();
    }

    const imageLoaded = () => {
        setImgLoading(false);
    }

    const renderAddProductDiv = (withImg) => {
        if (withImg) {
            return (<>
                <div className="flex-grow-1 cursor-pointer" onClick={setSelectedItem}>


                </div>
                <div onClick={addBagItem} className={itemAdded ? "bg-success py-3 w-100 px-5 text-center cursor-pointer rounded-pill" : "rounded-pill bg-dark py-3 w-100 px-5 text-center cursor-pointer text-white"}>
                    {itemAdded ? (<span > added successfully <i className="ml-2 fa fa-check"></i> </span>) : (<span >Add item to bag <i className="ml-2 fa fa-shopping-bag"></i> </span>)}
                </div>
            </>
            )
        }
        else {
            return (<div onClick={addBagItem} className={itemAdded ? "rounded-pill bg-success py-3 w-100 px-5 text-center cursor-pointer text-white" : "rounded-pill bg-dark py-3 w-100 px-5 text-center cursor-pointer text-white"}>
                {itemAdded ? (<span > added successfully <i className="ml-2 fa fa-check"></i> </span>) : (<span >Add item to bag <i className="ml-2 fa fa-shopping-bag"></i> </span>)}
            </div>)
        }

    }

    const renderAddProductMobile = (withImg) => {
        if (withImg) {
            return (<>
                <div className="flex-grow-1 cursor-pointer" onClick={setSelectedItem}>


                </div>
                <div onClick={addBagItem} className={"bg-transparent w-100 cursor-pointer d-flex justify-content-end"}>
                    <Avatar style={{ width: 40, height: 40, backgroundColor: "white" }}>
                        <i className="fa fa-shopping-bag text-dark"></i>
                    </Avatar>
                </div>
            </>
            )
        }
        else {
            return (<div onClick={addBagItem} className={"bg-transparent w-100 cursor-pointer d-flex justify-content-end"}>
                <Avatar style={{ width: 40, height: 40, backgroundColor: "white" }}>
                    <i className="fa fa-shopping-bag text-dark"></i>
                </Avatar>
            </div>)
        }
    }


    const renderForProduct = () => {
        return (
            <>
                <div className="product-desk h-100">
                    <a onMouseEnter={() => setShowAddProduct(true)}
                        onMouseLeave={() => setShowAddProduct(false)}
                        className={imgLoading ? "d-none" : "d-block"}
                        style={{ textDecoration: "none" }}
                    >
                        <div className="card bg-transparent text-white product-img-cont">
                            <img className={showAddProduct == true ? "home-img-cont-img card-img product-img" : "card-img product-img"} src={props.imgSrc} alt="Card image" onLoad={imageLoaded} />
                            <div className="card-img-overlay d-flex justify-content-center flex-column">
                                {showAddProduct == true ?
                                    renderAddProductDiv(true) : null
                                }
                            </div>
                        </div>
                    </a>
                    {
                        renderNoImgForProducts(imgLoading)
                    }
                </div>
                <div className="product-mobile h-100">
                    <div className={`card bg-transparent text-white home-img-cont ${imgLoading ? "d-none" : "d-block"}`}>
                            <img className={"card-img product-img"} src={props.imgSrc} alt="Card image" onLoad={imageLoaded} />
                            <div className="card-img-overlay d-flex justify-content-center flex-column p-2">
                                {
                                   renderAddProductMobile(true)
                                }
                            </div>
                        </div>
                    {
                        renderNoImgForMobileProducts(imgLoading)
                    }
                </div>
            </>
        );
    }

    const renderPlaceHolderAddProduct = () => {
        return (<div className={"bg-transparent py-3 w-100 px-5 text-center cursor-pointer"} style={{ color: 'transparent' }}>
            {(<span >Add item to bag <i className="ml-2 fa fa-shopping-bag"></i> </span>)}
        </div>)
    }

    const renderNoImgForProducts = (imgLoading) => {
        
        return (
            <a onMouseEnter={() => setShowAddProduct(true)}
                onMouseLeave={() => setShowAddProduct(false)}
                className={imgLoading ? "d-flex h-100" : "d-none"}
                style={{textDecoration: "none"} }
            >
                <div className={imgLoading ? "card bg-secondary bg-gradient text-dark flex-grow-1 w-100" : "d-none"}>
                    <div className="card-body d-flex flex-column  align-items-center">
                        <div className="flex-grow-1 d-flex flex-column justify-content-center cursor-pointer align-items-center" onClick={setSelectedItem}>
                            <h1 className="card-title pb-3 text-center">{"Melmir Cosmetics"}</h1>
                            <h2> <span><i className="fa fa-image"> </i> </span></h2>
                        </div>
                        {showAddProduct == true ?
                            renderAddProductDiv(false) : renderPlaceHolderAddProduct()
                        }
                        {/*  {renderAddProductDiv(false)}*/}
                    </div>
                </div>
            </a>
        );
    }

    const renderNoImgForMobileProducts = (imgLoading) => {

        return (
            <a 
                className={imgLoading ? "d-flex h-100" : "d-none"}
                style={{ textDecoration: "none" }}
            >
                <div className={imgLoading ? "card bg-secondary bg-gradient text-dark flex-grow-1 w-100" : "d-none"}>
                    <div className="card-body d-flex flex-column  align-items-center p-2">
                        <div className="flex-grow-1 d-flex flex-column justify-content-center cursor-pointer  align-items-center" onClick={setSelectedItem}>
                            <h1 className="card-title pb-3 text-center">{"Melmir Cosmetics"}</h1>
                            <h2> <span><i className="fa fa-image"> </i> </span></h2>
                        </div>
                        {
                            renderAddProductMobile(true) 
                        }
                        {/*  {renderAddProductDiv(false)}*/}
                    </div>
                </div>
            </a>
        );
    }



    return (
        props.isProduct ? renderForProduct() : props.imgSrc ? renderWithImg() : renderNoImg()
    );

}

export default InfoCard;
