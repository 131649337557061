import React, { Component } from 'react';
import InfoCard from './Shared/InfoCard';
import Product from './Shared/Product';
import ProductModal from './Shared/ProductModal';
import { AppContext } from './AppContext';
import { Modal, ModalHeader, ModalBody, Badge } from 'reactstrap';
import { converToDollar } from '../helpers/FormatHelper';
import LoadingPage from './Shared/LoadingPage';
import AOS from 'aos';
import "aos/dist/aos.css";
import _, { size } from 'lodash';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';

const styles = theme => ({
    infoLight: {
        color: "#00a0d1",
        border: "solid 1px #00a0d1",
        marginTop: "1rem",
    }
});
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ProductsA extends Component {


    constructor(props) {
        super(props);

        this.state = {
            productData: [],
            modalOpen: false,
            itemAddedModalOpen: false,
            selectedProduct: null,
            showSpinner: false,
            showDetails: false
        };

    }

    async componentDidMount() {

        this.context.toggleHeaderImg(null);
        AOS.init();

        const curProducts = JSON.parse(window.sessionStorage.getItem('products'));

        if (curProducts && curProducts.length > 0) {
            this.setState({ productData: curProducts });
        }
        else {
            let response = await fetch('/Product');
            let data = await response.json();
            window.sessionStorage.setItem("products", JSON.stringify(data));
            this.setState({ productData: data });
        }
    }


    getText = () => {
        return "This is some filler text until I am sent the proper descriptions for each product!";
    }

    addToBag = (data, quickAdd = false) => {
        //this.setState({addedToBag: true})
        //.toggleItemAddedModal();
        this.context.addBagItems(data);
        if (quickAdd) {
            this.toggleItemAddedModal(data);
        }
    }

    setSelectedProduct = (data) => {
        this.setState({
            selectedProduct: data,
            modalOpen: true
        });
    }

    toggleModal = () => {
        this.setState({
            modalOpen: !this.state.modalOpen
        })
    }

    toggleItemAddedModal = (data = null) => {
        if (data) {
            this.setState({
                selectedProduct: data,
                itemAddedModalOpen: !this.state.itemAddedModalOpen
            })
        }
        else {
            this.setState({
                itemAddedModalOpen: !this.state.itemAddedModalOpen
            })
        }
    }


    updateSelectedPrice = (data) => {
        var price = this.state.selectedProduct.product.sizesPrices[data];

        this.setState(state => ({
            selectedProduct: {
                ...state.selectedProduct,
                selectedPrice: {
                    cost: price,
                    size: data
                }
            }
        }));
    }

    showLoadingSpinner = () => {
        this.setState({
            showSpinner: true
        })
        setTimeout(() => {
            this.setState(prevState => ({
                showSpinner: false,
                modalOpen: false,
                itemAddedModalOpen: true
            }));
        }, 2000);
    }
    renderItemSectionDataGrid = (groupedData, key) => {
        const newDataOrder = _.sortBy(groupedData[key], data => data.product.name);
        return (
            newDataOrder.map((data) =>
                <Grid item xl={3} lg={4} md={4} sm={6} xs={6} className="align-items-stretch" key={data.product.name}>
                    <div className="pb-2 d-flex flex-column h-100" onClick={() => this.setSelectedProduct(data)}>
                        <Product title={data.product.name} imgSrc={data.product.images ? data.product.images[0] : null} description={data.product.description} cost={data.selectedPrice?.cost} setSelectedProduct={() => this.setSelectedProduct(data)} addToBag={() => this.addToBag(data, true)}> </Product>
                    </div>
                </Grid>
            )
        )
    }




    renderItemSectionTitle = (key) => {
        return (
            <Typography variant="h4" className="pl-1 overpass-font"> <b> {key} </b></Typography>
        )
    }

    renderItemsGrid = () => {

        var data = this.state.productData;
        var sortedData = _.sortBy(data, data => data.product.category);
        var groupedData = _.groupBy(sortedData, data => data.product.category)
        return (

            Object.keys(groupedData).map((key, idx) =>
                <Grid container spacing={2} direction="column" className="pb-5 pt-3" id={`${idx}_div`} ref={`${idx}_div`} key={`${idx}_div`}>
                    {this.renderItemSectionTitle(key)}
                    <Grid container spacing={2} direction="row">
                        {this.renderItemSectionDataGrid(groupedData, key)}
                    </Grid>
                </Grid>

            )
        )
    }


    renderItemLinks = () => {

        var data = this.state.productData;
        var sortedData = _.sortBy(data, data => data.product.category);
        var groupedData = _.groupBy(sortedData, data => data.product.category);
        var categoryImgs = ["../Soap_Bars.png", "../Body_Butter_Lotion.png", "../Body_Lotion.jpg", "../Deodorant.jpg", "../Face_Cream.jpg", "../Lotion_Bar.jpg"];
        return (
            <div className="py-5 product-links-row" >
                {
                    Object.keys(groupedData).map((key, idx) =>
                        //<NavLink tag={Link} className="btn btn-outline-dark mx-3" to={`#${idx}_div`}>{key }</NavLink>
                        //<Link activeClass="active" to={`#${idx}_div`} spy={true} smooth={true} offset={0} duration={500}>
                        //    {key}
                        //</Link>

                        /*                <a className="btn btn-outline-dark mx-1 product-links" key={key} onClick={() => this.scrollToDiv(`${idx}_div`)} > <Typography> {key} </Typography> </a>*/
                        <div key={key} onClick={() => this.scrollToDiv(`${idx}_div`)} className={`product-links mx-1 product_${idx}`} >
                            {/*<Avatar src={categoryImgs[idx]} variant="circle" alt="Card image" style={{ width: 150, height: 150 }}>*/}
                            {/*</Avatar>*/}
                          <h4 className="avatar-span overpass-font"> <b> {key} </b> </h4>
                        </div>
                    )
                }
                {/*<a className="btn btn-outline-dark mx-1 product-links" onClick={() => this.scrollToDiv(`keydiv`)} > {'key'} </a>*/}
                {/*<a className="btn btn-outline-dark mx-1 product-links" onClick={() => this.scrollToDiv(`oneTwo`)} > {'oneTwo'} </a>*/}
            </div>
        )
    }

    scrollToDiv = (element) => {
        var elem = document.getElementById(element);
        elem.scrollIntoView({ top: -15, behavior: "smooth", });
    }

    renderModal = (classes) => {
        //var s = this.state.selectedProduct?.product;
        return (
            <ProductModal
                selectedProduct={this.state.selectedProduct}
                modalOpen={this.state.modalOpen}
                toggleModal={this.toggleModal}
                showSpinner={this.state.showSpinner}
                addToBag={this.addToBag}
                showLoadingSpinner={this.showLoadingSpinner}
                classes={classes}
                updateSelectedPrice={this.updateSelectedPrice}
            >

            </ProductModal>
        );

    }


    renderItemAddedModal = (classes) => {
        return (
            <Dialog open={this.state.itemAddedModalOpen} TransitionComponent={Transition} onClose={this.toggleItemAddedModal} maxWidth={"xs"} fullWidth>
                <DialogTitle className="item-added-modal-border">
                    <Typography variant="h5" className="text-center" >{"Item added to your bag"} <i className="fa fa-check text-success"> </i>   </Typography>
                </DialogTitle>
                <DialogContent className="item-added-modal-border">
                    <div className="d-flex flex-row justify-content-center align-items-center">
                        <div className="d-flex justify-content-flex-start pr-3 flex-column col-4">
                            {
                                this.state.selectedProduct?.product?.images?.length > 0 ?
                                    (<img className={"added-modal-img"} src={this.state.selectedProduct?.product?.images[0]} alt="Card image" />) :

                                    (<InfoCard title={this.state.selectedProduct?.product?.name} isDropDown={true} />)

                            }
                        </div>
                        <div className="col-8">
                            <div className="added-mobile">
                                <Typography variant="subTitle2"> <b> {this.state.selectedProduct?.product?.name} </b> </Typography>
                                <br />
                                <Typography variant="subTitle1" className="mb-1"> Volume: <b> {this.state.selectedProduct?.selectedPrice?.size}</b>  </Typography>
                                <br />
                                <Typography variant="subTitle1" className="mb-1"> Price: <b> {`${converToDollar(this.state.selectedProduct?.selectedPrice?.cost)}`} </b>  </Typography>
                                <br />
                                <Typography variant="subTitle1" className="mb-1"> Quantity: <b> {this.state.selectedProduct?.quantity} </b> </Typography>
                            </div>

                        </div>
                    </div>
                    <div className="pt-2 d-flex justify-content-center pb-2">
                        <Button className="bag-button-prods mr-2" variant="outlined" size="small" onClick={this.toggleItemAddedModal}>
                            {"Continue Shopping"}
                        </Button>
                        <Button className="bag-button-prods" variant="outlined" size="small" onClick={() => this.props.history.push("/shopping-bag")}>
                            {"Checkout"}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        );

    }

    render() {
        const { classes } = this.props;
        return (
            <>
                <div className={(!this.state.productData || this.state.productData.length == 0) ? "d-block" : "d-none"}>
                    <LoadingPage> </LoadingPage>
                </div>
                <div className={(!this.state.productData || this.state.productData.length == 0) ? "d-none" : "d-block"}>
                    {/*<div className="product-image-div" data-aos="fade-in" data-aos-easing="linear" data-aos-duration="1000" id="top_div">*/}
                    {/*    <div className="text-center d-flex flex-column justify-content-between align-items-center py-5">*/}
                    {/*        <Typography variant="h2" className="product-text-color overpass-font">Our Products</Typography>*/}
                    {/*        <div className="product-separation-div"> </div>*/}
                    {/*        */}{/*<Typography className="prod-subTitle product-text-color header-subtitle-text" variant="subtitle1"> We provide a wide range of skin care products that will meet all your needs. </Typography>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="container-fluid product-container pt-3 pb-5">
                        {this.renderItemLinks()}
                        <>
                            {this.renderItemsGrid()}
                        </>

                        {this.renderModal(classes)}
                        {this.renderItemAddedModal(classes)}
                        <button onClick={() => this.scrollToDiv("top_div")} id="myBtn" className="rounded-pill" title="Go to top">Scroll to top</button>
                    </div>
                </div>
            </>
        );
    }
}
ProductsA.contextType = AppContext;

export const Products = withStyles(styles)(ProductsA);