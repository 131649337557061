import React, { Component } from 'react';
import ContactForm from './Shared/ContactForm'
import { AppContext } from './AppContext';
import AOS from 'aos';
import "aos/dist/aos.css";
import Typography from '@material-ui/core/Typography';
import { Modal, ModalHeader, ModalBody, ModalFooter, Badge } from 'reactstrap';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress'
import $ from 'jquery';

export class Contact extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            contactSubmitLoading: false
        };

    }

    componentDidMount() {
        this.context.toggleHeaderImg(null);
        //this.scrollToDiv();
        AOS.init();
    }


    scrollToDiv = () => {

        $('html, body').animate({
            scrollTop: $("#customContainer").offset().top - 250
        }, 1000);

        //var elem = document.getElementById("customContainer");
        //elem.scrollIntoView({ behavior: "smooth",  });
    }

    submitForm = async (name, email, message) => {
        this.setState({ modalOpen: true, contactSubmitLoading: true });

        const putOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name: name, email: email, message: message })
        };

        let res = await fetch('Contact/SaveContact', putOptions);
        if (res.ok) {
            this.setState({ modalOpen: true, contactSubmitLoading: false });
        }
        else {
            //say something went wrong
        }


    };
    toggleModal = () => {
        this.setState({
            modalOpen: !this.state.modalOpen
        })
    }

    renderFormSuccessful = () => {
        return (
            <Modal className="login-modal" isOpen={this.state.modalOpen} toggle={this.toggleModal} centered>
                <ModalHeader className="login-modal-header align-items-center" toggle={() => this.props.history.push("/")}><Typography variant="h5" className="pl-5 text-dark overpass-font"> Melissa Miriam Cosmetics </Typography> </ModalHeader>
                <ModalBody className="login-modal-body">
                    {
                        this.state.contactSubmitLoading ? (<div className="py-1 d-flex align-items-center flex-column">
                            <Typography variant="h6"> Hang tight, your information is being submitted.</Typography>
                            <CircularProgress />
                        </div>) :
                            <div className = "py-1 d-flex align-items-center flex-column">
                            <Typography variant = "h5"> Thank you for contacting us!</Typography>
                <Typography variant="subtitle2"> We will get back to you as soon as possible</Typography>
                <div className="d-flex justify-content-center py-2">
                    <Button className="bag-button-prods mr-2" variant="outlined" size="small" onClick={() => this.props.history.push("/products")}>
                        {"Start Shopping"}
                    </Button>
                    <Button className="bag-button-prods" variant="outlined" size="small" onClick={() => this.props.history.push("/")}>
                        {"Home"}
                    </Button>
                </div>
                        </div>
        
                    }
                </ModalBody>
            </Modal>
        );
    }


    render() {
        return (
            <>
                <div className="contact-image-div">
                    <div className="position-absolute text-center contact-text-div">
                        <div data-aos="fade-in" data-aos-easing="linear" data-aos-duration="1000">
                            <Typography variant="h2" className="header-title mb-4">Contact Us </Typography>
                        </div>
                        <div data-aos="fade-in" data-aos-easing="linear" data-aos-duration="1000">
                            <Typography variant="h6" className="header-sub-title" data-aos="fade-in" data-aos-easing="linear" data-aos-duration="1000">For more information, fill in your contact details below and we'll get back to you. </Typography>
                        </div>
                    </div>
                </div>
                <>
                    <ContactForm isHomeContact={false} submitForm={this.submitForm} />
                </>
                {this.renderFormSuccessful() }
            </>
        );
    }
}


Contact.contextType = AppContext;