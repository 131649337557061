import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { AppContext, defaultCartCount } from './AppContext';
import DropDown from './Shared/DropDown';
import Login from './Login'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge } from 'reactstrap';
import { Typography } from '@material-ui/core';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.state = {
            collapsed: true,
            modalOpen: false
        };
    }


    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    toggleModal() {
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }


    renderModal() {

        return (
            <Modal className="login-modal" isOpen={this.state.modalOpen} toggle={this.toggleModal} centered>
                <ModalHeader className="login-modal-header align-items-center" toggle={this.toggleModal}><Typography variant="h5" className="pl-5 text-dark overpass-font"> Melissa Miriam Cosmetics </Typography> </ModalHeader>
                <ModalBody className="login-modal-body">
                    <Login toggleLoginModal={this.toggleModal}> </Login>
                </ModalBody>
            </Modal>
        );

    }


    render() {

        let ctxt = this.context;
        return (
            <>
                <header >
                    <div className={ctxt.showHeaderImg ? "home-image-div custom-header fixed-top" : "d-none"} style={{ zIndex: '0' }}>
                        <div className="position-absolute text-center row max-150">
                            <div className="col-2 max-150">
                                <Typography className="overpass-font brown-header-text nav-header-title" variant="h2"> Welcome</Typography>
                                <Typography className="brown-header-text nav-header-sub-title" variant="h5"> To</Typography>
                            </div>
                            <div className="col-8 nav-header-title-div max-150">
                                <div className="mt-5"> 
                                    <Typography variant="h2" className="overpass-font brown-header-text nav-header-title"> Melissa Miriam </Typography>
                                    <Typography variant="h4" className="overpass-font brown-header-text nav-header-sub-title"> Cosmetics</Typography>
                                    <Typography variant="h4" className="overpass-font nav-header-look-forward brown-header-text nav-header-sub-title"> We look forward to walking with you on your skin care jorney</Typography>
                                </div>
                            </div>
                            <div className="col-2 max-150">
                            </div>
                        </div>
                    </div>
                    <Navbar className={`navbar-expand-sm navbar-toggleable-sm brown-bg nav-border-bottom ${ctxt.showHeaderImg ? 'custom-nav' : ''} ${this.state.collapsed ? '' : 'custom-nav-active'}`} light>
                        <Container fluid>
                            {/*<NavbarBrand tag={Link} className="text-black" to="/">melmircosmetics</NavbarBrand>*/}
                            <NavbarToggler className={this.state.collapsed ? "mr-2" : "d-none"} onClick={this.toggleNavbar} />
                            <div className={this.state.collapsed ? "d-none" : "d-flex justify-content-end w-100"} onClick={this.toggleNavbar} >
                                <span> <i className="fa fa-times text-white font-lg"> </i> </span>

                            </div>
                            <div className={`${this.state.collapsed ? 'navbar-center' : 'd-none'} `}>
                                {/*<NavLink tag={Link} to="/" className="pr-2 pl-0 py-0">*/}
                                {/*    <img class="logo" src="/Logo.jpg" height="100" width="100" />*/}
                                {/*</NavLink>*/}
                            </div>
                            <ul className={`${this.state.collapsed ? 'nav-login-mobile ' : 'd-none'} `}>
                                {/*{ctxt.isLoggedIn == true ?*/}
                                {/*    (*/}
                                {/*        <NavItem>*/}
                                {/*            <DropDown isUser={true}> </DropDown>*/}
                                {/*        </NavItem>) : (<NavItem>*/}
                                {/*            <NavLink className="text-black cursor-pointer overpass-font-nav" onClick={this.toggleModal}>Log In</NavLink>*/}
                                {/*        </NavItem>)*/}
                                {/*}*/}
                                <NavItem>
                                    <NavLink className="text-black custom-nav-link">
                                        <DropDown isUser={false} isMobile={true}> </DropDown>
                                    </NavLink>
                                </NavItem>
                            </ul>
                            <Collapse className={`d-sm-inline-flex flex-sm-row justify-content-between ${!this.state.collapsed ? 'navmenu-mob' : ''}`} isOpen={!this.state.collapsed} navbar>
                                {!this.state.collapsed ?
                                    (<ul className="navbar-nav flex-grow-1 align-items-center">
                                        <div className="w-100 menu-splitter">
                                            <NavItem onClick={this.toggleNavbar}>
                                                <NavLink tag={Link} className="text-white overpass-font-nav" to="/">Home</NavLink>
                                            </NavItem>
                                        </div>
                                        <div className="w-100 menu-splitter">
                                            <NavItem onClick={this.toggleNavbar}>
                                                <NavLink tag={Link} className="text-white overpass-font-nav" to="/About">About</NavLink>
                                            </NavItem>
                                        </div>
                                        <div className="w-100 menu-splitter">
                                            <NavItem onClick={this.toggleNavbar}>
                                                <NavLink tag={Link} className="text-white overpass-font-nav" to="/Products">Our Products</NavLink>
                                            </NavItem>
                                        </div>
                                        <div className="w-100 menu-splitter">
                                            <NavItem onClick={this.toggleNavbar}>
                                                <NavLink tag={Link} className="text-white overpass-font-nav" to="/Contact">Contact Us</NavLink>
                                            </NavItem>
                                        </div>
                                        <div className="d-flex flex-row justify-content-between w-100 pt-5">
                                            <button className="btn btn-dark white-border">
                                                <NavItem onClick={this.toggleNavbar}>
                                                    <NavLink tag={Link} className="text-white overpass-font-nav" to="/shopping-bag"> <span> <i className="fa fa-shopping-bag pr-2"></i></span>  <span> {ctxt.bagCount}</span> </NavLink>
                                                </NavItem>
                                            </button>
                                            <button className="btn btn-dark white-border">
                                                <NavItem onClick={this.toggleNavbar}>
                                                    <NavLink className="text-white cursor-pointer overpass-font-nav" onClick={this.toggleModal}>My account  <i className={"fa fa-user pl-2"}> </i></NavLink>
                                                </NavItem>
                                            </button>
                                        </div>
                                    </ul>) :
                                    <ul className="navbar-nav align-items-center w-33 justify-content-around">
                                        <NavItem>
                                            <NavLink tag={Link} className="text-black overpass-font-nav" to="/">Home</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} className="text-black overpass-font-nav" to="/About">About</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} className="text-black overpass-font-nav" to="/Products">Our Products</NavLink>
                                        </NavItem>

                                    </ul>

                                }
                                <div class="navbar-center-desk w-33 d-flex justify-content-center">
                                    {/*<NavLink tag={Link} to="/" className="pr-2 pl-0 py-0">*/}
                                    {/*    <img class="logo" src="/Logo.jpg" height="250" />*/}
                                    {/*</NavLink>*/}
                                </div>

                                <ul className="navbar-nav align-items-center nav-login-desk w-33 justify-content-around">
                                    <NavItem>
                                        <NavLink tag={Link} className="text-black overpass-font-nav" to="/Contact">Contact Us</NavLink>
                                    </NavItem>
                                    {ctxt.isLoggedIn == true ?
                                        (
                                            <NavItem>
                                                <DropDown isUser={true}> </DropDown>
                                            </NavItem>) : (<NavItem>
                                                <NavLink className="text-black cursor-pointer overpass-font-nav" onClick={this.toggleModal}>Log In</NavLink>
                                            </NavItem>)
                                    }
                                    <NavItem>
                                        <NavLink className="text-black cursor-pointer" to="/shopping-bag">
                                            <DropDown isUser={false}> </DropDown>
                                        </NavLink>
                                    </NavItem>
                                </ul>
                            </Collapse>

                        </Container>
                    </Navbar>
                </header>
                {this.renderModal()}
            </>
        );
    }
}

NavMenu.contextType = AppContext;