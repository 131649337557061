import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input, FormText, Card, CardText, CardBody, CardTitle, CardFooter  } from 'reactstrap';
//export interface InfocardProps {
//    imgSrc: string;
//    title: string;
//    bodyText: string;
//    body?: JSX;
//}


const ContactForm = (props) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const renderHomeForm = () => {
        return (<>
            <Form className="d-flex justify-content-center flex-column" onSubmit={handleContactSubmission }>
                <div className="d-inline-flex contact-form-ne">
                    <FormGroup className="w-50 mr-2">
                        <Label className="text-white" for="name_form">Name:</Label>
                        <Input type="text" name="name_form" id="name_form" rows="2" placeholder="Enter Name" />
                    </FormGroup>
                    <FormGroup className="w-50">
                        <Label className="text-white" for="email_form">Email:</Label>
                        <Input type="email" name="email_form" id="email_form" rows="2" placeholder="Enter Email" />
                    </FormGroup>
                </div>
                <div className="w-100">
                    <FormGroup>
                        <Label className="text-white" for="message_form">Message:</Label>
                        <Input type="textarea" name="message_form" id="message_form" rows="4" placeholder="Enter Message" />
                    </FormGroup>
                </div>
                <div className="text-left mt-2">
                    <button className="home-form-button btn" type="submit">Send</button>
                </div>
            </Form>
        </>)
    }

    const renderContactForm = () => {
        return (
            <Form className="d-flex justify-content-center flex-row flex-wrap w-50" style={{ maxWidth: 750 }} onSubmit={handleContactSubmission }>
                <div className="col-6 bg-light contact-card-flex">
                    <Card className="border-none bg-light">
                        <CardBody className="mt-5">
                            <CardTitle><h1 className="text-dark text-center">{"Get In Touch"} </h1></CardTitle>
                            <CardText>
                                <div className="pt-3 text-center">
                                    <a href="https://www.instagram.com/melmircosmetics" className="text-brown mx-2"><i className="fa fa-instagram ig-color contact-icon"> </i></a>
                                    <a href="mailto:customerservice@melmircosmetics.com" className="text-brown mx-2"><i className="fa fa-envelope contact-icon"> </i></a>
                                </div>
                            </CardText>
                        </CardBody>
                    </Card>

                </div>
                <div className="col-6 contact-card-flex">
                        <FormGroup>
                            <Label className="text-dark" for="name_form">Name:</Label>
                        <Input type="text" name="name_form" id="name_form" rows="2" placeholder="Enter Name" onChange={(e) => setName(e.target.value)}  />
                        </FormGroup>
                        <FormGroup>
                            <Label className="text-dark" for="email_form">Email:</Label>
                        <Input type="email" name="email_form" id="email_form" rows="2" placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)}  />
                        </FormGroup>
                        <FormGroup>
                            <Label className="text-dark" for="message_form">Message:</Label>
                        <Input type="textarea" name="message_form" id="message_form" rows="2" placeholder="Enter Message" onChange={(e) => setMessage(e.target.value)}  />
                    </FormGroup>
                    <div className="w-100 text-center">
                        <button className="contact-form-button btn" type="submit">Send</button>
                        </div>
                </div>
            </Form>
        )
    }



    const handleContactSubmission = async (e) => {
        e.preventDefault();
        props.submitForm(name, email, message);

    };


    return (
        <div className={props.isHomeContact? "contact-form-home": "contact-form-contact"}>
            {props.isHomeContact ? (<h1 className="text-center text-white"> Contact Us </h1>) : null}
            {props.isHomeContact ? (renderHomeForm()) : renderContactForm()}
        </div>
    );

}

export default ContactForm;
