import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { About } from './components/About';
import { Products } from './components/Products';
import { Contact } from './components/Contact';
import  Bag  from './components/bag';
import  Login  from './components/Login';
import { AppContext, defaultBagCount, defaultIsLoggedin } from './components/AppContext';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import './custom.css'
import { Redirect } from 'react-router-dom';
import { each } from 'jquery';


const theme = createTheme({
    status: {
        danger: '#e53e3e',
    },
    palette: {
        infoLight: {
            main: '#00a0d1',
            contrastText: '#fff',
        }
    },
});



export default class App extends Component {

    constructor(props) {
        super(props);

        this.logUserIn = (email) => {
            window.sessionStorage.setItem('user', email);
            this.setState(state => ({
                userEmail: email,
                isLoggedIn: true
            }));
        };

        this.logUserOut = () => {
            window.sessionStorage.clear();
            this.setState(state => ({
                userEmail: null,
                isLoggedIn: false
            }));
        }


        //call this and pass into context
        this.addBagItems = (items) => {
            let newItems = [...this.state.bagItems, items];
            window.sessionStorage.setItem('bag', JSON.stringify(newItems));
            window.sessionStorage.setItem('PurchasedBag', JSON.stringify(newItems));
            this.setState(state => ({
                bagCount: this.countBag(newItems),
                bagItems: newItems,
                purchasedItems: newItems
            }));
        }

        //this.setPurchasedItems = (items) => {
        //    window.sessionStorage.setItem('PurchasedBag', JSON.stringify(items));
        //    window.sessionStorage.setItem('bag', null);
        //    this.setState(state => ({
        //        purchasedItems: items,
        //        bagCount: 0,
        //        bagItems: null
        //    }));
        //}

        this.removeBagItem = (item) => {
            let newItems = this.state.bagItems.filter(i => i.id !== item.id);
            window.sessionStorage.setItem('bag', JSON.stringify(newItems));
            window.sessionStorage.setItem('PurchasedBag', JSON.stringify(newItems));
            this.setState(state => ({
                bagCount: this.countBag(newItems),
                bagItems: newItems,
                purchasedItems: newItems
            }));
        }

        this.updateBagItems = (items) => {
            window.sessionStorage.setItem('bag', JSON.stringify(items));
            window.sessionStorage.setItem('PurchasedBag', JSON.stringify(items));
            this.setState(state => ({
                bagCount: this.countBag(items),
                bagItems: items,
                purchasedItems: items
            }));
        }
        this.toggleHeaderImg = (page) => {
            if (page != null) {

                this.setState(state => ({
                    showHeaderImg: false
                }));
            }
            else {
                this.setState(state => ({
                    showHeaderImg: true
                }));
            }
        }
        this.emptyBag = () => {
            window.sessionStorage.setItem('bag', null);
            this.setState(state => ({
                bagCount: 0,
                bagItems: []
            }));
        }


        const preLogged = window.sessionStorage.getItem('user');
        const curBag = JSON.parse(window.sessionStorage.getItem('bag'));
        const curPurchasedItems = JSON.parse(window.sessionStorage.getItem('PurchasedBag'));
        this.state = {
            bagCount: curBag && curBag.length > 0 ? this.countBag(curBag): 0,
            bagItems: curBag || [],
            purchasedItems: curPurchasedItems || [],
            isLoggedIn: !!preLogged,
            userEmail: preLogged || null,
            logUserIn: this.logUserIn,
            logUserOut: this.logUserOut,
            addBagItems: this.addBagItems,
            removeBagItem: this.removeBagItem,
            updateBagItems: this.updateBagItems,
            showHeaderImg: true,
            toggleHeaderImg: this.toggleHeaderImg,
            emptyBag: this.emptyBag
        };
    }

    countBag(items) {
        var count = 0;
        for (var i = 0; i < items.length; i++) {
            var val = items[i].quantity;
            count += val;
        }

        return count;
    }

    render() {
        return (
            <AppContext.Provider value={this.state}>
            <Layout>
                <Route exact path='/' component={Home} />
                <Route path='/About' component={About} />
                <Route path='/Contact' component={Contact} />
                <Route path='/Products' component={Products} />
                    <Route path='/shopping-bag' component={Bag} />
                </Layout>
                </AppContext.Provider>
        );
    }
}
